import { createContext, useContext, useState } from "react";
import AdminNovoRelatorio from "../Pages/Admin-Novo-Relatorio/AdminNovoRelatorio";
import ApiService from "../Service/ApiService";
import { useNavigate } from "react-router-dom";
import { PORTAL_PATHS } from "../Router/Path";

export const AdminNovoRelatorioContext = createContext();

export const useAdminNovoRelatorioContext = () => {
    return useContext(AdminNovoRelatorioContext);
}

/*
    const Posicionamento = [
        { find: 'rua', label: 'Rua', type: 'text' },
        { find: 'bairro', label: 'Bairro', type: 'text' },
        { find: 'linhaDeEndereco', label: 'Linha de Endereço', type: 'text' },
        { find: 'cidade', label: 'Cidade', type: 'text' },
        { find: 'estado', label: 'Estado', type: 'text' },
        { find: 'pais', label: 'País', type: 'text' },
        { find: 'MicroEscalaText', label: 'Micro Escala', type: 'text' },
        { find: 'MesoEsacalaText', label: 'Meso Escala', type: 'text' },
        { find: 'MacroEscalaText', label: 'Macro Escala', type: 'text' },
    ];

    let Usos_do_solo = [
        { find: 'areaTotal', label: 'Área Total', type: 'text' },
        { find: 'quartos', label: 'Quartos', type: 'text' },
        { find: 'garagem', label: 'Garagem', type: 'text' },
        { find: 'banheiro', label: 'Banheiro', type: 'text' },
        { find: 'varandaAreaGourmet', label: 'Varanda Área Gourmet', type: 'text' },
        { find: 'areaDeServico', label: 'Área de Serviço', type: 'text' },
        { find: 'tipologiaMaisProcurada', label: 'Tipologia Mais Procurada', type: 'text' },
        { find: 'avaliacaoEstimadaIa', label: 'Avaliação Estimada IA', type: 'number' },
        { find: 'OverviewSobreAreaAnalisada', label: 'Overview sobre Área Analisada', type: 'text' },
        { find: 'integracaoGlobal', label: 'Integração Global', type: 'textarea' },
        { find: 'escolhaGlobal', label: 'Escolha Global', type: 'textarea' },
        { find: 'integracaoLocal', label: 'Integração Local', type: 'textarea' },
        { find: 'escolhaLocal', label: 'Escolha Local', type: 'textarea' },
        { find: 'nivelIntegracao', label: 'Nível de Integração', type: 'number' },
        { find: 'nivelEscolha', label: 'Nível de Escolha', type: 'number' },
        { find: 'indicacaMapaIntegracaoGlobalLocal', label: 'Indicação Mapa Integração Global Local', type: 'text' },
        { find: 'indicacaMapaEscolhaGlobalLocal', label: 'Indicação Mapa Escolha Global Local', type: 'text' },
        { find: 'indicacaMapaPotencialDesenvolvimento', label: 'Indicação Mapa Potencial Desenvolvimento', type: 'text' }
    ]
    let Densidade = [
        { name: 'quantidadeHectaresBairro', label: 'Quantidade de Hectares do Bairro', type: 'text' },
        { name: 'numeroEstimadoDeMoradias', label: 'Número Estimado de Moradias', type: 'text' },
        { name: 'quantidadeDeMoradiasPorHectar', label: 'Quantidade de Moradias por Hectar', type: 'text' },
        { name: 'grauDensidadeDoBairro', label: 'Grau de Densidade do Bairro', type: 'text' },
        { name: 'motivadoresDensidade', label: 'Motivadores de Densidade', type: 'text' },
        { name: 'quantidadeDeHabitantesBairro', label: 'Quantidade de Habitantes do Bairro', type: 'text' },
        { name: 'habitantePorMoradiaExtimado', label: 'Habitante por Moradia Estimado', type: 'text' }
    ]
    */

const AdminNovoRelatorioContextProvider = () => {
    const [Relatorio, setRelatorio] = useState({});
    const [etapa, setEtapa] = useState("Posicionamento");
    let navigate = useNavigate();
    const handleOnChange = (e) => {
        const { name, value } = e.target;
        setRelatorio(prevData => ({
            ...prevData,
            [name]: value
        }));
        console.log(Relatorio);
    };

    /*
    const handleUploadImage = (e) => {
        const { name, files } = e.target;
        if (files && files[0]) {
            const file = files[0];
            const reader = new FileReader();

            reader.onloadend = () => {
                // Converter a imagem em bytes e armazenar no estado
                const byteArray = new Uint8Array(reader.result);
                setRelatorio(prevData => ({
                    ...prevData,
                    [name]: byteArray
                }));
                console.log("Imagem convertida para bytes:", byteArray);
            };

            reader.readAsArrayBuffer(file);
        }
    };
    */
    const handleUploadImage = (e) => {
        const { name, files } = e.target;
        if (files && files[0]) {
            const file = files[0];
            const reader = new FileReader();
    
            reader.onloadend = () => {
                const base64String = reader.result.split(',')[1]; 
                setRelatorio(prevData => ({
                    ...prevData,
                    [name]: base64String 
                }));
                console.log("Imagem convertida para base64:", base64String);
            };
    
            reader.readAsDataURL(file); 
        }
    };
    

    async function handleSubmit(e){
        e.preventDefault();
        await ApiService.post("/Relatorio/NovoRelatorio/iniciar", Relatorio)
        navigate(PORTAL_PATHS.ADMIN_RELATORIOS_PENDENTES);
    }

    function handleSectionChange(etapa) {
        return setEtapa(etapa);
    }

    let value = {
        handleOnChange,
        handleSectionChange,
        etapa,
        handleUploadImage,
        handleSubmit,
    };

    return (
        <AdminNovoRelatorioContext.Provider value={value}>
            <AdminNovoRelatorio />
        </AdminNovoRelatorioContext.Provider>
    )
}
export default AdminNovoRelatorioContextProvider;
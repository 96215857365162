import { Link } from "react-router-dom";
import Alertas from "../../Components/Alertas/Alertas";
import RealEstateAnalysisChart from "../../Components/Charts/GraficoValorVendaVsValoraluguel";
import SecaoLateral from "../../Components/SecaoLateral/SecaoLateral";
import { PATHS, PORTAL_PATHS } from "../../Router/Path";
import { useDashboardContext } from "../../Context/Dashboard.context";
import analizzaLogo  from  "../../Static/Analizza/logo-analizza-2.png"

export default function Dashboard() {

let {Alerts} = useDashboardContext

    return (
        <div className="Dashboard">

            <SecaoLateral
                Greeting_OR_Title={"Seja bem vindo!"}
            />
            <div className="BodyDashboard">
                <div className="AlertsAndDos">
                    <section className="Todos">
                        <section className="Atalhos">
                            <div className="TitleTodos">
                                <h3>
                                    Atalhos
                                </h3>
                            </div>
                            <div className="buttons">
                                <Link to={PORTAL_PATHS.NEW_REPORT} className="ButtonToDo">
                                    Solicitar um novo Relatório
                                </Link>
                                <Link to={PORTAL_PATHS.MY_REPORTS} className="ButtonToDo">
                                    Meus Relatórios
                                </Link>
                                <a className="ButtonToDo removeBackground" href={PORTAL_PATHS.ANALIZZA} target="_blank"> 
                                    <img src={analizzaLogo} alt="analizzaLogo" />
                                </a>
                            </div>

                        </section>
                        <p className="Subtitle">Indicadores gerais sobre a cidade de Brusque</p>
                        <div className="IndicesMonitoramento">
                            <section className="row">
                                <div className="indicador">
                                    <h3>PIB (R$ - Milhões):</h3>
                                    <span>8.723,26</span>
                                </div>
                                <div className="indicador">
                                    <h3>IDHM:</h3>
                                    <span>0.80</span>
                                </div>
                            </section>
                            <section className="row">
                                <div className="indicador">
                                    <h3>Populacao:</h3>
                                    <span>141.385</span>
                                </div>
                                <div className="indicador">
                                    <h3>Media Salarial</h3>
                                    <span>R$ 3.140,76</span>
                                </div>
                            </section>
                        </div>
                    </section>
                    <section className="Alertas">
                        <Alertas 
                            alerts={Alerts}
                        />
                    </section>
                </div>
                <div className="ToDo">
                    <section className="Graficos">
                        <RealEstateAnalysisChart />
                    </section>
                </div>
            </div>
        </div>
    )
}
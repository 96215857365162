import { createContext, useContext, useEffect } from "react";
import Dashboard from "../Pages/Dashboard/Dashboard";
import { PORTAL_PATHS } from "../Router/Path";
import LocalStorageService from "../Service/LocalStorageService";

export const DashboardContext = createContext();

export function
  useDashboardContext() {
  return useContext(DashboardContext)
}

export default function DashboardContextProvider() {
  useEffect(() => {
    const coin = LocalStorageService.getToken();

    if (!coin) {
      window.location.href = PORTAL_PATHS.LOGIN;
    }
  }, []);

  //let [Alerts, setAlerts] = useState([]);

  async function GetUserLoggedData() {    
    let userData = LocalStorageService.getUserData();
    let fullname = userData.nome + userData.sobreNome;
    let firstName = userData.nome;
    LocalStorageService.setFullName(fullname);
    LocalStorageService.setName(firstName);
    return userData;
  }



  useEffect(() => {
    GetUserLoggedData()
  })

  let value = {
    //Alerts

  }

  return (
    <DashboardContext.Provider value={value}>
      <Dashboard />
    </DashboardContext.Provider>
  )
}
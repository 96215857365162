const ENDPOINTS = {
    USER: {
        USER_COLLECT_DATA:"/User/Auth/loggedData/getdata",
        RESET_PASSWORD: "/User/Auth/redefinir/senha",
    },
    ADMIN: {
        ADMIN_OVERVIEW_COLECTOR: "/admin/coletar/todos/dados/Gerais/vizzion",
    }



}
export default ENDPOINTS;
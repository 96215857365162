export const PATHS = {
    HOME: "/",
    SOLUCAO: "/sobre-a-solucao-vizzion",
    CONTATO: "https://api.whatsapp.com/send?phone=554797633714",
    SOBRE: "/sobre",
    PARCEIROS: "/parceiros",
    TRABALHE_CONOSCO: "/trabalhe-conosco",
    SMART_TIME_FOR_CITTIES: "/smart-vizzion-for-smart-citties",
    BANCO_DE_TALENTOS: "/banco-de-talentos",
    NOT_FOUND: "*",
    WHATSAPP: "https://api.whatsapp.com/send?phone=554797633714",
    DNA_DO_IMOVEL: "/vizzion/dna-do-imovel",
    INSIGHTS:"/vizzion/saas/v-insights",
    NOT_FOUND:"*",
}
export const PORTAL_PATHS = {
    DASHBOARD_HOME: '/vizzion/telescope/vizzion-dashboadr',
    LOGIN: '/vizzion/telescope/login',
    MY_REPORTS: '/vizzion/telescope/My-reports',
    CONFIGS: '/vizzion/telescope/my-account-config',
    ALERTS: '/vizzion/telescope/new-alerts',
    NEW_REPORT: '/vizzion/telescope/novoRelatorio',
    ACESS_REPORT: '/vizzion/telescope/acess-report',
    NOT_FOUND:"*",
    ANALIZZA: "https://analizza-frontend-analizza.fizmcj.easypanel.host/",
    ADMIN:"/vizzion/telescope/admin",
    ADMIN_RELATORIOS_PENDENTES: "/vizzion/the/better/ones/works/here/relatorios/pendnetes",
    ADMIN_NOVO_RELATORIO:"/vizzion/telescope/admin/novo/relatorio",
    ADMIN_TODOS_RELATORIOS_ENVIADOS:"/vizzion/telescope/admin/todos/relatorios/enviados",
    ADMIN_NOVO_USUARIO:"/vizzion/telescope/admin/novo/usuario",
    ADMIN_NOVO_USUARIO_VIZZION:"/vizzion/telescope/admin/novo/usuario/Administrativo/sec/v1/vizzion",
    ADMIN_ATUALIZAR_RELATORIO: '/vizzion/telescope/admin/atualizar',
    PREVIEW:"/vizzion/telescope/Dna/preview",

}
import { Link } from "react-router-dom"
import icone from "../../Portal-Static/sinal-de-aviso.png";

export default function Alertas({
    alerts
}) {
    return (
        <div className="AlertComponent">
            <span className="AlertComponentHeader">
                <h2>Alertas</h2>
                <p>Confira seus ultimos alertas</p>
            </span>
            <div className="BodyAlert">
                {alerts && (
                    <>
                        {alerts.map((item, index) => (
                            <Alert
                                key={index}
                                titulo={item.titulo}
                                importancia={item.importancia}
                                descricaoResumida={item.descricaoResumida}
                                id={item.id}
                            />
                        ))}
                    </>
                )}
                {/*
                <Alert
                    titulo="AlertaTeste"
                    descricaoResumida="Este é um alerta de teste para mostrar como os alertas funcionam!"
                    id={1}
                    importancia={3}
                />
                <Alert
                    titulo="AlertaTeste"
                    descricaoResumida="Este é um alerta de teste para mostrar como os alertas funcionam!"
                    id={1}
                    importancia={2}
                />
                <Alert
                    titulo="AlertaTeste"
                    descricaoResumida="Este é um alerta de teste para mostrar como os alertas funcionam!"
                    id={1}
                    importancia={1}
                />
                <Alert
                    titulo="AlertaTeste"
                    descricaoResumida="Este é um alerta de teste para mostrar como os alertas funcionam!"
                    id={1}
                    importancia={3}
                />
                <Alert
                    titulo="AlertaTeste"
                    descricaoResumida="Este é um alerta de teste para mostrar como os alertas funcionam!"
                    id={1}
                    importancia={2}
                />
                */}
            </div>
        </div>
    )
}

export function Alert({
    titulo, id, importancia, descricaoResumida
}) {
    let url = `/alerts/alerta/${id}`;
    return (
        <div className="AlertCompBody">
            <div className="Importancia">
                {importancia && importancia === 3 && (
                    <span className="altaImportancia">
                        <img className="Imagem" src={icone} alt="ico" />
                    </span>
                )}
                {importancia && importancia === 2 && (
                    <span className="MediaImportancia">
                        <img className="Imagem" src={icone} alt="ico" />
                    </span>
                )}
                {importancia && importancia === 1 && (
                    <span className="BaixaImportancia">
                        <img className="Imagem" src={icone} alt="ico" />
                    </span>
                )}
            </div>
            <div className="texts">
                <h3 className="titulo">{titulo}</h3>
                <p className="descricao">{descricaoResumida}</p>
            </div>
            {id && (
                <Link to={url} className="ButtonAlert">
                    Ver Alerta
                </Link>
            )}
        </div>
    )
}
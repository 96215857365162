import React, { Suspense, useEffect, useState, createContext, useContext } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { PATHS, PORTAL_PATHS } from "./Path";
import { Loading } from "../Components/Loading/Loading";
import LocalStorageService from "../Service/LocalStorageService";
import AdminGeralContextProvider from "../Context/AdminGeral.context.js";
import AdminNovoRelatorioContextProvider from "../Context/AdminNovoRelatorio.context.js";
import DashboardContextProvider from "../Context/Dashboard.context"

// Lazy load dos componentes
const Home = React.lazy(() => import("../Pages/Home/Home"));
const VizzionDna = React.lazy(() => import("../Pages/VizzionDna/VizzionDna"));
const VizzionInsights = React.lazy(() => import("../Pages/VizzionInsights/VizzionInsights"));
const NewReportFullContextProvider = React.lazy(() => import("../Context/SolicitacaoRelatorio.context"));
const MeusRelatoriosContextProvider = React.lazy(() => import("../Context/MeusRelatorios.context"));
const MeuPerfilContextProvider = React.lazy(() => import("../Context/MeuPerfil.context.js"));
const ReportcontextProvider = React.lazy(() => import("../Context/Report.context"));
const LoginContextProvider = React.lazy(() => import("../Context/Login.context"));
const NotFound = React.lazy(() => import("../Pages/NotFound/NotFound"));
const NovoUsuarioContextProvider = React.lazy(() => import("../Context/NovoUsuarioVizzion.context.js"));
const NovoUsuarioAdminContextProvider = React.lazy(() => import("../Context/NovoUsuarioVizzionAdmin.context.js"));
const RelatoriosPendentesOuIniciadosContextProvider = React.lazy(() => import("../Context/RelatoriosPendentesOuIniciados.context.js"))
const AtualizarRelatorioContextProvider = React.lazy(() => import("../Context/AtualizarRelatorio.context.js"));

const AuthContext = createContext({ token: null, loading: true });
const AuthProvider = ({ children }) => {
  const [authState, setAuthState] = useState({
    token: null,
    loading: true,
    seVizzionAdminUser: null,
  });

  useEffect(() => {
    const verifyToken = async () => {
      const token = await LocalStorageService.getToken();
      const user = await LocalStorageService.getUserData();

      setAuthState({
        token: token || null,
        loading: false,
        seVizzionAdminUser: user?.seVizzionAdminUser || null,
      });
    };

    verifyToken();
  }, []);

  useEffect(() => {
    console.log("AuthState atualizado:", authState);
  }, [authState]);


  return <AuthContext.Provider value={authState}>{children}</AuthContext.Provider>;
};
const useAuth = () => useContext(AuthContext);
const ProtectedRoute = ({ children }) => {
  const { token, loading } = useAuth();

  if (loading) return <Loading />;
  return token ? children : <Navigate to={PORTAL_PATHS.LOGIN} />;
};

const ProtectedRouteVizzionAdmin = ({ children }) => {
  const { token, loading, seVizzionAdminUser } = useAuth();

  if (loading) return <Loading />;
  return token ? children : <Navigate to={PORTAL_PATHS.LOGIN} />;
}

export default function RouterController() {
  return (
    <AuthProvider>
      <Router>
        <Suspense fallback={<Loading />}>
          <Routes>
            <Route path={PATHS.HOME} element={<Home />} />
            <Route path={PATHS.DNA_DO_IMOVEL} element={<VizzionDna />} />
            <Route path={PATHS.INSIGHTS} element={<VizzionInsights />} />
            <Route path={PORTAL_PATHS.LOGIN} element={<LoginContextProvider />} />
            <Route
              path={PORTAL_PATHS.DASHBOARD_HOME}
              element={
                <ProtectedRoute>
                  <DashboardContextProvider />
                </ProtectedRoute>
              }
            />
            <Route
              path={PORTAL_PATHS.NEW_REPORT}
              element={
                <ProtectedRoute>
                  <NewReportFullContextProvider />
                </ProtectedRoute>
              }
            />
            <Route
              path={PORTAL_PATHS.MY_REPORTS}
              element={
                <ProtectedRoute>
                  <MeusRelatoriosContextProvider />
                </ProtectedRoute>
              }
            />
            <Route
              path={PORTAL_PATHS.CONFIGS}
              element={
                <ProtectedRoute>
                  <MeuPerfilContextProvider />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${PORTAL_PATHS.ACESS_REPORT}/:id`}
              element={
                <ProtectedRoute>
                  <ReportcontextProvider />
                </ProtectedRoute>
              }
            />
            <Route
              path={PORTAL_PATHS.ADMIN}
              element={
                <ProtectedRouteVizzionAdmin>
                  <AdminGeralContextProvider />
                </ProtectedRouteVizzionAdmin>
              }
            />
            <Route
              path={PORTAL_PATHS.ADMIN_NOVO_RELATORIO}
              element={
                <ProtectedRouteVizzionAdmin>
                  <AdminNovoRelatorioContextProvider />
                </ProtectedRouteVizzionAdmin>
              }
            />
            <Route
              path={PORTAL_PATHS.ADMIN_NOVO_USUARIO}
              element={
                <ProtectedRouteVizzionAdmin>
                  <NovoUsuarioContextProvider />
                </ProtectedRouteVizzionAdmin>
              }
            />
            <Route
              path={PORTAL_PATHS.ADMIN_NOVO_USUARIO_VIZZION}
              element={
                <ProtectedRouteVizzionAdmin>
                  <NovoUsuarioAdminContextProvider />
                </ProtectedRouteVizzionAdmin>
              }
            />
            <Route
              path={PORTAL_PATHS.ADMIN_RELATORIOS_PENDENTES}
              element={
                <ProtectedRouteVizzionAdmin>
                  <RelatoriosPendentesOuIniciadosContextProvider />
                </ProtectedRouteVizzionAdmin>
              }
            />
            <Route
              path={`${PORTAL_PATHS.ADMIN_ATUALIZAR_RELATORIO}/:ReportID`}
              element={
                <ProtectedRouteVizzionAdmin>
                  <AtualizarRelatorioContextProvider />
                </ProtectedRouteVizzionAdmin>
              }
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
      </Router>
    </AuthProvider>
  );
}

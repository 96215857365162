import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import ApiService from "../../Service/ApiService";


const ValorVendaChart = () => {
  const [series, setSeries] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchChartData = async () => {
      try {
        const response = await ApiService.get("/BaseData/homeChats");
        const backendData = response.data;

        const valorVendaData = generateDayWiseTimeSeries(
          backendData.valoresVenda,
          backendData.datas
        );

        setSeries([{ name: "Valor médio de Venda", data: valorVendaData }]);
        setLoading(false);
      } catch (error) {
        console.error("Erro ao buscar os dados do gráfico:", error);
      }
    };
    fetchChartData();
  }, []);

  const generateDayWiseTimeSeries = (values, dates) => {
    let series = [];
    for (let i = 0; i < values.length; i++) {
      let x = new Date(dates[i]).getTime();
      series.push([x, values[i]]);
    }
    return series;
  };

  const options = {
    chart: {
      type: "area",
      height: 200,
      foreColor: "#999",
      dropShadow: {
        enabled: true,
        enabledSeries: [0],
        top: -2,
        left: 2,
        blur: 5,
        opacity: 0.06,
      },
    },
    colors: ["#16715E"],
    stroke: {
      curve: "smooth",
      width: 3,
    },
    xaxis: {
      type: "datetime",
    },
    yaxis: {
      labels: {
        formatter: (value) => `R$ ${value.toFixed(2)}`,
      },
    },
  };

  return loading ? (
    <p>Carregando dados...</p>
  ) : (
    <Chart options={options} series={series} type="area" height={200} />
  );
};

const ValorAluguelChart = () => {
  const [series, setSeries] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchChartData = async () => {
      try {
        const response = await ApiService.get("/BaseData/homeChats");
        const backendData = response.data;
        console.log(response)
        const valorAluguelData = generateDayWiseTimeSeries(
          backendData.valoresAluguel,
          backendData.datas
        );

        setSeries([{ name: "Valor médio de Aluguel", data: valorAluguelData }]);
        setLoading(false);
      } catch (error) {
        console.error("Erro ao buscar os dados do gráfico:", error);
      }
    };
    fetchChartData();
  }, []);

  const generateDayWiseTimeSeries = (values, dates) => {
    let series = [];
    for (let i = 0; i < values.length; i++) {
      let x = new Date(dates[i]).getTime();
      series.push([x, values[i]]);
    }
    return series;
  };

  const options = {
    chart: {
      type: "area",
      height: 200,
      foreColor: "#999",
      dropShadow: {
        enabled: true,
        enabledSeries: [0],
        top: -2,
        left: 2,
        blur: 5,
        opacity: 0.06,
      },
    },
    colors: ["#167139"],
    stroke: {
      curve: "smooth",
      width: 3,
    },
    xaxis: {
      type: "datetime",
    },
    yaxis: {
      labels: {
        formatter: (value) => `R$ ${value.toFixed(2)}`,
      },
    },
  };

  return loading ? (
    <p>Carregando dados...</p>
  ) : (
    <Chart options={options} series={series} type="area" height={200} />
  );
};

const RealEstateAnalysis = () => {
  const containerStyle = {
    display: "flex",
    justifyContent: "space-around",
  };

  const chartStyle = {
    width: "45%",
  };
  
  const h1Style = {
    textAlign: "center",
    marginBottom: "2vh",
  };

  return (
    <div>
      <h2 style={h1Style}>Historico de valor de venda e aluguel</h2>
      <div style={containerStyle}>
        <div style={chartStyle}>
          <ValorVendaChart />
        </div>
        <div style={chartStyle}>
          <ValorAluguelChart />
        </div>
      </div>
    </div>
  );
};

export default RealEstateAnalysis;

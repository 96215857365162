import { createContext, useContext, useEffect, useState } from "react";
import AdminGeral from "../Pages/Admin-geral/AdminGeral";
import LocalStorageService from "../Service/LocalStorageService";
import ApiService from "../Service/ApiService";
import ENDPOINTS from "../Config/EndpointsBackend";

export const AdminGeralContext = createContext();
export const useAdminGeralContext = () => { return useContext(AdminGeralContext) }
const AdminGeralContextProvider = () => {
    const [dadosDoAdmin, setDadosDoAdmin] = useState({
        usuariosTotais: 0,
        relatoriosEntregues: 0,
        imoveisAnalisados: 0,
        cidadesAtuadas: 0,
        quantidadeDeUsuariosAdmin: 0,
        quantidadeDeLoginsUltimosCincoDias: 0,
        quantidadeDeProjetosAnalizza: 0,
    });

    async function getAdminData() {
        try {
            const response = await ApiService.get(ENDPOINTS.ADMIN.ADMIN_OVERVIEW_COLECTOR);
            if (response && response.data) {
                setDadosDoAdmin({
                    usuariosTotais: response.data.usuariosTotais || 0,
                    relatoriosEntregues: response.data.relatoriosEntregues || 0,
                    imoveisAnalisados: response.data.imoveisAnalisados || 0,
                    cidadesAtuadas: response.data.cidadesAtuadas || 0,
                    quantidadeDeUsuariosAdmin: response.data.quantidadeDeUsuariosAdmin || 0,
                    quantidadeDeLoginsUltimosCincoDias: response.data.quantidadeDeLoginsUltimosCincoDias || 0,
                    quantidadeDeProjetosAnalizza: response.data.quantidadeDeProjetosAnalizza || 0,
                });
            }
        } catch (error) {
            console.error("Erro ao buscar dados do admin:", error);
        }
    }

    useEffect(() => {
        getAdminData();
    }, []);


    let value = {
        dadosDoAdmin
    }
    return (
        <AdminGeralContext.Provider value={value}>
            <AdminGeral />
        </AdminGeralContext.Provider>
    )
}
export default AdminGeralContextProvider
import ChangeSection from "../../Components/BotaoTrocaSecao/ChangeSection"
import SectRow from "../../Components/Row-novo-relatorio/SectRow"
import SecaoLateral from "../../Components/SecaoLateral/SecaoLateral"
import { useAdminNovoRelatorioContext } from "../../Context/AdminNovoRelatorio.context"

let AdminNovoRelatorio = () => {

    let { etapa, handleUploadImage, handleOnChange, handleSubmit } = useAdminNovoRelatorioContext()

    return (
        <div className="AdminNovoRelatorio">
            <SecaoLateral
                Greeting_OR_Title={"Novo relatório"}
            />
            <div className="AdminNovoRelatorioBody">
                <h2> Novo Relatorio </h2>
                {etapa == "Posicionamento" && (
                    <>
                        {/*
                        <SectRow
                            title={"Posicionamento"}
                            objetosDeInput={Posicionamento}
                            handleOnChange={handleOnChange}
                            isTextAreas={false}
                        />
                        <ChangeSection handleChangeSection={handleSectionChange} section="UsosDoSolo" />
                        */}
                        <div className="InitReport">
                            <div className="Escala-new-report">
                                <h2>Micro Escala</h2>
                                <div className="flex-escala-newReport">
                                    <div className="form-control-new-report">
                                        <label htmlFor="escalaMicroDescritivo"> Descrição da microescala </label>
                                        <textarea type="text" id="escalaMicroDescritivo" name="escalaMicroDescritivo" placeholder="MicroEscala" onChange={handleOnChange} />
                                    </div>
                                    <div className="form-control-new-report">
                                        <label htmlFor="escalaMicroImagem">Imagem da caracteroizacao</label>
                                        <input type="file" name="escalaMicroImagem" id="escalaMicroImagem" onChange={handleUploadImage} />
                                    </div>
                                </div>
                            </div>
                            <div className="Escala-new-report">
                                <h2>Meso Escala</h2>
                                <div className="flex-escala-newReport">
                                    <div className="form-control-new-report">
                                        <label htmlFor="escalaMesoDescritivo"> Descrição da mesoescala </label>
                                        <textarea type="text" id="escalaMesoDescritivo" name="escalaMesoDescritivo" placeholder="MicroEscala" onChange={handleOnChange} />
                                    </div>
                                    <div className="form-control-new-report">
                                        <label htmlFor="escalaMesoImagem">Imagem da caracteroizacao</label>
                                        <input type="file" name="escalaMesoImagem" id="escalaMesoImagem" onChange={handleUploadImage} />
                                    </div>
                                </div>
                            </div>
                            <div className="Escala-new-report">
                                <h2>Macro Escala</h2>
                                <div className="flex-escala-newReport">
                                    <div className="form-control-new-report">
                                        <label htmlFor="escalaMacroDescritivo"> Descrição da macroescala </label>
                                        <textarea type="text" id="escalaMacroDescritivo" name="escalaMacroDescritivo" placeholder="MicroEscala" onChange={handleOnChange} />
                                    </div>
                                    <div className="form-control-new-report">
                                        <label htmlFor="escalaMacroImagem">Imagem da caracteroizacao</label>
                                        <input type="file" name="escalaMacroImagem" id="escalaMesoImagem" onChange={handleUploadImage} />
                                    </div>
                                </div>
                            </div>
                            <button onClick={handleSubmit} className="NovoRelatorioAdminButton">
                                Salvar Novo Relatorio
                            </button>
                        </div>
                    </>
                )}

                {/*
                    {etapa == "UsosDoSolo" && (
                        <>
                            <ChangeSection handleChangeSection={handleSectionChange} section="Posicionamento" type="retornar" />
                            <SectRow
                                title={"Usos do Solo"}
                                objetosDeInput={Usos_do_solo}
                                handleOnChange={handleOnChange}
                                isTextAreas={true}
                            />
                            <ChangeSection handleChangeSection={handleSectionChange} section="Densidade" />
                        </>
                    )}
                    {etapa == "Densidade" && (
                        <>
                            <ChangeSection handleChangeSection={handleSectionChange} section="UsosDoSolo" type="retornar" />
                            <SectRow
                                title={'Densidade'}
                                objetosDeInput={Densidade}
                                handleOnChange={handleOnChange}
                            />
                            <ChangeSection handleChangeSection={handleSectionChange} section="UsosDoSolo" />
                        </>
                    )}
                    {etapa == "Densidade" && (
                        <>
                            <ChangeSection handleChangeSection={handleSectionChange} section="UsosDoSolo" type="retornar" />
                            <SectRow
                                title={'Densidade'}
                                objetosDeInput={Densidade}
                                handleOnChange={handleOnChange}
                            />
                            <ChangeSection handleChangeSection={handleSectionChange} section="UsosDoSolo" />
                        </>
                    )}
                */}
            </div>
        </div>
    )
}
export default AdminNovoRelatorio
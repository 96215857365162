import api from "../Config/api";

const ApiService = {
    get: (endpoint, params = {}) =>
        api.get(endpoint, { params }).catch(handleError),
    post: (endpoint, body = {}) =>
        api.post(endpoint, body).catch(handleError),
    put: (endpoint, body = {}) =>
        api.put(endpoint, body).catch(handleError),
    delete: (endpoint) =>
        api.delete(endpoint).catch(handleError)
};

const handleError = (error) => {
    console.error(error);

    if (error.response?.status === 401) {
        window.location.href = "/vizzion/telescope/login";
    }
    throw error; // Repropaga o erro para que ele possa ser tratado localmente
};

export default ApiService;

import { LocalStorageNames } from "../Config/LocalStorageNames";

const LocalStorageService = {
    setToken: (token) => { if (token) { localStorage.setItem(LocalStorageNames.TOKEN, token) } },
    getToken: () => { return localStorage.getItem(LocalStorageNames.TOKEN) },
    setRole: (role) => { if (role) { localStorage.setItem(LocalStorageNames.ROLE, role) } },
    getRole: () => { return localStorage.getItem(LocalStorageNames.ROLE) },
    setUserData: (userData) => { if (userData) { localStorage.setItem(LocalStorageNames.USER_DATA, JSON.stringify(userData)) } },
    getUserData: () => {
        const userData = localStorage.getItem(LocalStorageNames.USER_DATA);
        return userData ? JSON.parse(userData) : null
    },
    removeToken: () => { localStorage.removeItem(LocalStorageNames.TOKEN) },
    removeRole: () => { localStorage.removeItem(LocalStorageNames.ROLE) },
    removeUserData: () => { localStorage.removeItem(LocalStorageNames.USER_DATA) },
    clear: () => { localStorage.clear() },
    setFullName: (fullname) => { localStorage.setItem(LocalStorageNames.FULL_NAME, fullname) },
    getFullName: () => {localStorage.getItem(LocalStorageNames.FULL_NAME)},
    setName: (name) => {localStorage.setItem(LocalStorageNames.NAME, name)},
    getName: () => {localStorage.getItem(LocalStorageNames.NAME)}
};

export default LocalStorageService;

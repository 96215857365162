import React, { useEffect, useState } from "react";
import Logo from "../../Static/Vizzion_logo.png";
import vetores from "../../Portal-Static/Vetores.svg";
import ProfileIcon from "../../Portal-Static/perfilDeUsuario.png"
import Botao from "./Botao";

import Relatorios from "../../Portal-Static/iconeDeRelatorio.png"
import MeusRelatorios from "../../Portal-Static/IconeDeMeusRelatorios.png"
import MenuIcone from "../../Portal-Static/MenuIcone.png"
import { Link } from "react-router-dom";
import { PORTAL_PATHS } from "../../Router/Path";
import LocalStorageService from "../../Service/LocalStorageService";
import AdminIcon from "../../Portal-Static/admin-panel.png";


export default function SecaoLateral({
  ProfilePicture,
  Greeting_OR_Title,
}) {
  let [showAdminData, setShowAdminData] = useState(false);
  let [popup, setPopup] = useState(false)
  let FirstNameUserLogged = localStorage.getItem('@name')

  function handleOpenPopup() {
    setPopup(!popup)
  }
  function handleLogout(){
    localStorage.clear()
  }

  useEffect(() => {
    const user = LocalStorageService.getUserData();
    if (user?.seVizzionAdminUser === "VIZZION_ADMIN_USER") {
      setShowAdminData(true);
    }
  }, []);

  return (
    <div className="SecaoLateral">
      <div className="CorpoDaSecaoLateral">
        <span className="Profile" onClick={handleOpenPopup}>
          <img src={!ProfilePicture ? ProfileIcon : ProfilePicture} alt="perfil" className="ProfilePicture" />
          <p className="NomeUsuarioLogado">{FirstNameUserLogged || "Perfil"}</p>
        </span>
        <h3 className="TitleSecaoLateral">{Greeting_OR_Title}</h3>
        <section className="ButtonsSections">

          <Botao
            icone={Relatorios}
            path={PORTAL_PATHS.NEW_REPORT}
            texto="Novo Relatorio"
            color="claro"
          />
          <Botao
            icone={MeusRelatorios}
            path={PORTAL_PATHS.DASHBOARD_HOME}
            texto="Home"
            color="escuro"
          />
          <Botao
            icone={MenuIcone}
            path={PORTAL_PATHS.MENU}
            texto="Menu"
            color="escuro"
          />
          {showAdminData && (
            <Botao 
              icone={AdminIcon}
              path={PORTAL_PATHS.ADMIN}
              texto="Admin"
              color="claro"
            />
          )}
        </section>
      </div>
      {popup && popup === true && (
        <div className="PopupProfile"> 
          <ul className="listOfProfileOptions">
            <li className="itemPopup">
              <Link to={PORTAL_PATHS.CONFIGS}> MeuPerfil </Link>
            </li>
            <li className="itemPopup">
              <Link to={PORTAL_PATHS.MY_REPORTS}> Meus Relatorios </Link>
            </li>
            <li className="itemPopup">
              <Link to={PORTAL_PATHS.NEW_REPORT}> Novo Relatorio </Link>
            </li>
            <li className="Sair">
              <Link to={PORTAL_PATHS.LOGIN} onClick={handleLogout}> Sair </Link>
            </li>
          </ul>
        </div>
      )}
      <img src={vetores} alt="vetores" className="VetoresBanner" />
      <img src={Logo} alt="Logo" className="LogoDaSecaoLateral" />
    </div>
  );
}

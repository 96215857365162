import { Link } from "react-router-dom";
import SecaoLateral from "../../Components/SecaoLateral/SecaoLateral";
import { useAdminGeralContext } from "../../Context/AdminGeral.context";
import { PORTAL_PATHS } from "../../Router/Path";

const AdminGeral = () => {

    let { dadosDoAdmin } = useAdminGeralContext();

    return (
        <div className="AdminGeral">
            <SecaoLateral
                Greeting_OR_Title={"Seção administrativa"}
            />
            <div className="Body-admin-geral">
                <div className="Dados-gerais-admn">
                    <h2>Dados gerais</h2>
                    <div className="Secao-de-dados-gerais">
                        <span className="data-geral green">
                            <p>Usuarios totais</p>
                            {dadosDoAdmin.usuariosTotais}
                        </span>
                        <span className="data-geral">
                            <p>Relatorios Entregues</p>
                            {dadosDoAdmin.relatoriosEntregues}
                        </span>
                        <span className="data-geral green">
                            <p>Imóveis Analisados</p>
                            {dadosDoAdmin.imoveisAnalisados}
                        </span>
                    </div>
                    <div className="Secao-de-dados-gerais">
                        <span className="data-geral">
                            <p>Logins ultiam semana</p>
                            {dadosDoAdmin.quantidadeDeLoginsUltimosCincoDias}
                        </span>
                        <span className="data-geral green">
                            <p>Usuarios Admin</p>
                            {dadosDoAdmin.quantidadeDeUsuariosAdmin}
                        </span>
                        <span className="data-geral">
                            <p>Projetos Analizza</p>
                            {dadosDoAdmin.quantidadeDeProjetosAnalizza}
                        </span>
                    </div>
                    <span className="Body-admin-actions">
                        <h3> Atalhos princípais </h3>
                        <span className="actions-bdy">

                            <div className="admin-action">
                                <Link to={PORTAL_PATHS.ADMIN_RELATORIOS_PENDENTES}>Novo Relatório</Link>
                            </div>
                            <div className="admin-action">
                                <Link to={PORTAL_PATHS.ADMIN_NOVO_USUARIO}>Novo Usuario Analizza</Link>
                            </div>
                            <div className="admin-action">
                                <Link to={PORTAL_PATHS.ADMIN_NOVO_USUARIO}>Novo Usuario Vizzion</Link>
                            </div>
                            <div className="admin-action">
                                <Link to={PORTAL_PATHS.ADMIN_NOVO_USUARIO_VIZZION}>Novo Admin</Link>
                            </div>
                        </span>
                    </span>
                </div>
            </div>
        </div>
    )
}

{/*
    usuariosTotais: 0,
    relatoriosEntregues: 0,
    imoveisAnalisados: 0,
    cidadesAtuadas: 0,
    quantidadeDeLoginsUltimosCincoDias: 0,
    quantidadeDeUsuariosAdmin: 0,
    quantidadeDeProjetosAnalizza: 0,
*/}
export default AdminGeral;
import { Link } from "react-router-dom";


export default function Botao({
    icone, texto, color,path
}) {
    return (
        <Link className={`Botao ${color}`} to={path}>
            <img src={icone} alt="icone" className="Icone" />
            <p>{texto}</p>
        </Link>
    )
}